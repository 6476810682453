
		var translate_subtotal			= "Subtotaal";
		var translate_subtotalexclvat	= "Totaal excl. BTW";
		var translate_vat				= "BTW";
		var translate_total				= "Totaal";
		var translate_discount_applied	= "Uw kortingscode is toegepast";
		var translate_discount_invalid	= "De opgegeven code is ongeldig";

		var user_type					= "";
	


